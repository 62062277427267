<template>
  <div>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder">
            {{ $t("Contact person") }}
          </h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">
            {{ $t("Update your contact person's information") }}
          </span>
        </div>
        <div class="card-toolbar">
          <button v-b-modal.create-contact-person-modal class="btn btn-light-primary font-weight-bolder">
            <span class="svg-icon menu-icon">
              <inline-svg src="/media/svg/icons/General/User.svg" />
            </span>
            {{ $t("Contact person") }}
          </button>
        </div>
      </div>
      <div class="card-body detail">
        <datatable ref="table" :table-props="tableProps" :total="total" :options.sync="tableOptions"
          :per-page-options="perPageOptions">
          <template #[`cell.physicalperson`]="{ item }">
            <div class="text-capitalize d-flex align-items-center">
              <avatar :avatar-text="item.physicalperson.name?.charAt(0)"
                :avatar-image="item.physicalperson.avatar_display">
              </avatar>
              <span class=" font-weight-bolder ml-2">{{ item.physicalperson.name }} {{ item.physicalperson.surname
              }}</span>
            </div>
          </template>

          <template #[`cell.phone`]="{ item }">
            <div v-if="item.physicalperson.telcontact_set">
              <div v-for="tel in item.physicalperson.telcontact_set" :key="tel.id" class="py-1">
                {{ tel.number }}
                <span v-if="tel.main" class="label label-inline label-light-success font-weight-bolder ml-4">
                  {{ $t("Main") }}</span>
                <div class="text-muted">{{ getPhoneType(tel.kind) }}</div>
              </div>
            </div>
          </template>

          <template #[`cell.mail`]="{ item }">
            <div v-if="item.physicalperson.extraemail_set">
              <div v-for="email in item.physicalperson.extraemail_set" :key="email.id" class="py-1">
                {{ email.email }}
                <span v-if="showMainIcon(item)" class="text-muted ">
                  <span v-if="email.main" class="label label-inline label-light-success font-weight-bolder ml-4">
                    {{ $t("Main") }}</span>
                </span>
              </div>
            </div>
          </template>

          <template #[`cell.role`]="{ item }">
            <div class="text-muted font-weight-bolder">{{ item.role.name }}</div>
          </template>

          <template #[`cell.actions`]="{ item }">
            <div class="d-flex">
              <b-button v-b-tooltip.hover :title="$t('Edit')" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                @click="edit(item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                </span>
              </b-button>
              <b-button v-b-tooltip.hover :title="$t('Delete')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                @click="remove(item)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/General/Trash.svg" />
                </span>
              </b-button>
            </div>
          </template>
        </datatable>
      </div>
    </div>

    <validation-observer ref="validationObserver" v-slot="{ handleSubmit }" tag="form">
      <b-modal id="create-contact-person-modal" size="lg" hide-footer @hide="onCreateModalHidden">
        <template #modal-header>
          <div class=" w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
            <h3>{{ $t("Contact person") }}</h3>
            <div class="d-flex align-items-center">
              <b-button variant="success" class="mr-2" :class="{ 'spinner spinner-light spinner-right': isLoading }"
                @click="handleSubmit(submit)">{{ $t("Save") }}</b-button>
              <b-button @click="closeModal">
                {{ $t("Cancel") }}
              </b-button>
            </div>
          </div>
        </template>
        <legal-person-contact-person-form :roles="contactRoles" :contacts="contacts"
          @input="onModalInput"></legal-person-contact-person-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompanyTelephoneService from "@/core/services/company/company-telephone.service";
import CompanyEmailService from "@/core/services/company/company-email.service";
import CompanyContactRolesService from "@/core/services/company/company-contactroles.service";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import LegalPersonContactPersonForm from "@/view/components/forms/legal-person/LegalPersonContactPersonForm.vue";
import CompanyService from "@/core/services/company/company.service";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import icons from "@/core/config/icons.js";
import Swal from "sweetalert2";
import { backendErrorSwal } from "@/core/helpers/swal";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import { successToast } from "@/core/helpers";

export default {
  components: {
    Datatable,
    LegalPersonContactPersonForm,
  },
  props: {
    legalPerson: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons,
      contact: {
        logo_tus: null,
        name: "",
        surname: "",
        telcontact_set: [],
        extraemail_set: [],
        gender: "",
      },
      groups: "",
      TELEPHONE_KINDS: [],
      EMAIL_KINDS: [],
      GENDERS: [],
      GROUPS: [],
      contactRoles: [],
      isLoading: false,
      fields: [
        {
          key: "physicalperson",
          label: this.$t("Physical person"),
          sortable: true,
          class: "align-middle",
        },
        { key: "phone", label: this.$t("Telephone"), sortable: false, class: "align-middle" },
        { key: "mail", label: this.$t("Email"), sortable: false, class: "align-middle" },
        { key: "role", label: this.$t("Role"), sortable: true, class: "align-middle" },
        { key: "actions", label: this.$t("Actions"), class: "align-end col-actions" },
      ],
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      requestBody: {
        avatar_tus: null,
        name: "",
        surname: "",
        telcontact_set: [],
        extraemail_set: [],
        gender: "",
        groups: "",
        roleName: "",
      },
      contacts: []
    };
  },
  computed: {
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        "no-sort-reset": true,
        responsive: true,
        "tbody-tr-class": this.trClass,
      };
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Legal person"), route: { name: "manage-legal-person" } },
      { title: this.$t("Contact Person") },
    ]);
    try {
      const [telephoneKinds, emailKinds, genders, roles] = await Promise.all([
        CompanyTelephoneService.getKinds(),
        CompanyEmailService.getKinds(),
        PhysicalPersonDetailService.getGenders(),
        CompanyContactRolesService.getAll({ perPage: 100, page: 1, fields: "id,name,description,public" }),
      ]);
      this.TELEPHONE_KINDS = telephoneKinds.map(x => ({ value: x.value, text: x.display_name }));
      this.EMAIL_KINDS = emailKinds.map(x => ({ value: x.value, text: x.display_name }));
      this.GENDERS = genders.map(el => ({ value: el.value, text: el.display_name }));
      this.contactRoles = roles.results;
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    showMainIcon(item) {
      return item.physicalperson.extraemail_set.length > 1;
    },
    getPhoneType(kind) {
      return kind === "MOB" ? this.$t("Mobile") : this.$t("Phone");
    },
    closeModal() {
      this.$bvModal.hide("create-contact-person-modal");
    },
    onCreateModalHidden() {
      this.requestBody = null;
    },
    onModalInput(val) {
      this.requestBody = { ...val };
    },
    async submit() {
      this.isLoading = true;
      let params = null;
      if (this.requestBody.id) {
        params = {
          company: this.legalPerson.id,
          physicalperson: this.requestBody.id,
          role: this.requestBody.role,
        }
      } else {
        delete this.requestBody.roleName;
        const res = await PhysicalPersonService.create(this.requestBody);
        params = {
          company: this.legalPerson.id,
          physicalperson: res.id,
          role: this.requestBody.groups,
        }
      }

      await CompanyService.createContact(params).then(() => {
        this.$refs.table.refresh();
        successToast();
      }).catch(err => {
        backendErrorSwal(err)
        console.log(err);
      }).finally(() => {
        this.closeModal();
        this.isLoading = false;
      });
    },
    edit(item) {
      this.$router.push({
        name: "detail-physical-person",
        params: { userID: item.physicalperson.id },
      });
    },
    itemProvider(ctx, callback) {
      CompanyService.getCompanyContactPerson({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
        companyId: this.legalPerson.id,
        expand: "physicalperson,role",
        fields: "id,company,role.id,role.name,physicalperson.id,physicalperson.name,physicalperson.surname,physicalperson.telcontact_set,physicalperson.extraemail_set",
      }).then(result => {
        this.contacts = result.map(el => el.physicalperson.id)
        callback(result)
      }).catch((error) => {
        console.log('Error:', error)
        backendErrorSwal(error, "Failed to load items");
        callback([]);
      });
    },
    remove(item) {
      Swal.fire({
        title: this.$t("Delete"),
        text: `${this.$t("Are you sure you want to delete the contact person ")} ${item.physicalperson.name} ${item.physicalperson.surname}?`,
        icon: "warning",
        confirmButtonClass: "btn btn-suuccess",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t("No, do NOT delete"),
        cancelButtonClass: "btn",
      }).then(res => {
        if (res.isConfirmed) {
          this.isLoading = true;
          CompanyService.deleteCompanyContactPerson(this.legalPerson.id, item.id).then(() => {
            successToast();
          }).catch(err => {
            console.error(err);
            backendErrorSwal(err, err?.response?.data?.error);
          }).finally(() => {
            this.$refs.table.refresh();
            this.isLoading = false;
          })
        }
      });
    }
  },
};
</script>
<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>
