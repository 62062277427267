var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-start flex-column"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("Contact person")) + " ")]), _c('span', {
    staticClass: "text-muted font-weight-bold font-size-sm mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("Update your contact person's information")) + " ")])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.create-contact-person-modal",
      modifiers: {
        "create-contact-person-modal": true
      }
    }],
    staticClass: "btn btn-light-primary font-weight-bolder"
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/General/User.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Contact person")) + " ")])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('datatable', {
    ref: "table",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.physicalperson",
      fn: function fn(_ref) {
        var _item$physicalperson$;
        var item = _ref.item;
        return [_c('div', {
          staticClass: "text-capitalize d-flex align-items-center"
        }, [_c('avatar', {
          attrs: {
            "avatar-text": (_item$physicalperson$ = item.physicalperson.name) === null || _item$physicalperson$ === void 0 ? void 0 : _item$physicalperson$.charAt(0),
            "avatar-image": item.physicalperson.avatar_display
          }
        }), _c('span', {
          staticClass: "font-weight-bolder ml-2"
        }, [_vm._v(_vm._s(item.physicalperson.name) + " " + _vm._s(item.physicalperson.surname))])], 1)];
      }
    }, {
      key: "cell.phone",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.physicalperson.telcontact_set ? _c('div', _vm._l(item.physicalperson.telcontact_set, function (tel) {
          return _c('div', {
            key: tel.id,
            staticClass: "py-1"
          }, [_vm._v(" " + _vm._s(tel.number) + " "), tel.main ? _c('span', {
            staticClass: "label label-inline label-light-success font-weight-bolder ml-4"
          }, [_vm._v(" " + _vm._s(_vm.$t("Main")))]) : _vm._e(), _c('div', {
            staticClass: "text-muted"
          }, [_vm._v(_vm._s(_vm.getPhoneType(tel.kind)))])]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell.mail",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.physicalperson.extraemail_set ? _c('div', _vm._l(item.physicalperson.extraemail_set, function (email) {
          return _c('div', {
            key: email.id,
            staticClass: "py-1"
          }, [_vm._v(" " + _vm._s(email.email) + " "), _vm.showMainIcon(item) ? _c('span', {
            staticClass: "text-muted"
          }, [email.main ? _c('span', {
            staticClass: "label label-inline label-light-success font-weight-bolder ml-4"
          }, [_vm._v(" " + _vm._s(_vm.$t("Main")))]) : _vm._e()]) : _vm._e()]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell.role",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-muted font-weight-bolder"
        }, [_vm._v(_vm._s(item.role.name))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm mr-2",
          attrs: {
            "title": _vm.$t('Edit')
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.$t('Delete')
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Trash.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  })], 1)]), _c('validation-observer', {
    ref: "validationObserver",
    attrs: {
      "tag": "form"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var handleSubmit = _ref6.handleSubmit;
        return [_c('b-modal', {
          attrs: {
            "id": "create-contact-person-modal",
            "size": "lg",
            "hide-footer": ""
          },
          on: {
            "hide": _vm.onCreateModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
              }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Contact person")))]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.isLoading
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v(_vm._s(_vm.$t("Save")))]), _c('b-button', {
                on: {
                  "click": _vm.closeModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_c('legal-person-contact-person-form', {
          attrs: {
            "roles": _vm.contactRoles,
            "contacts": _vm.contacts
          },
          on: {
            "input": _vm.onModalInput
          }
        })], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }